import { Feature } from '@arcadehq/shared/types'

import { AccountMixin } from '../AccountCore'
import { getSubfield, setOrDeleteSubfield } from '../helpers'

export interface Privacy {
  preventIndexing: boolean
  disableIpTracking: boolean
  disableEventTracking: boolean
  disableInternalTracking: boolean

  setPreventIndexing: (preventIndexing: boolean) => Promise<boolean>
  setDisableIpTracking: (disableIpTracking: boolean) => Promise<boolean>
  setDisableEventTracking: (disableEventTracking: boolean) => Promise<boolean>
}

export const privacy: AccountMixin<Privacy> = (core, { team }) => ({
  get preventIndexing() {
    // TODO check if in a user context

    return !!getSubfield(team, 'features', Feature.PreventIndexing)
  },

  get disableIpTracking() {
    // TODO check if in a user context

    return !!getSubfield(team, 'features', Feature.DisableIPTracking)
  },

  get disableEventTracking() {
    // TODO check if in a user context

    return !!getSubfield(team, 'features', Feature.DoNotTrack)
  },

  get disableInternalTracking() {
    // TODO check if in a user context

    return !!getSubfield(
      team,
      'features',
      Feature.DisableInternal3rdPartyTrackers
    )
  },

  setPreventIndexing(preventIndexing: boolean) {
    if (team) {
      return setOrDeleteSubfield(
        team,
        'features',
        Feature.PreventIndexing,
        preventIndexing,
        core.userId
      )
    }
    return Promise.resolve(false) // TODO user
  },

  setDisableIpTracking(disableIpTracking: boolean) {
    if (team) {
      return setOrDeleteSubfield(
        team,
        'features',
        Feature.DisableIPTracking,
        disableIpTracking,
        core.userId
      )
    }
    return Promise.resolve(false) // TODO user
  },

  setDisableEventTracking(disableEventTracking: boolean) {
    if (team) {
      return setOrDeleteSubfield(
        team,
        'features',
        Feature.DoNotTrack,
        disableEventTracking,
        core.userId
      )
    }
    return Promise.resolve(false) // TODO user
  },
})
